body {
  margin: 0;
  font-family:serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  color: #333;
  text-align: center;
  font-size: xx-large;
}

h2 {
  color: #444;
}

h3 {
  color: #444;
}

p {
  color: #666;
  line-height: 1.6;
  font-size: larger;
}

a {
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
}

a:hover {
  color: #0056b3;
}

.page-container-div {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}