.About {
    text-align: left;
}

.About h2 {
    margin-bottom: -10px;
    font-size: larger;
}

.About li {
    color: #666;
    line-height: 1.6;
    font-size: larger;
}

