
.Contact .page-container-div {
    pointer-events: none;
    color: white;
}

.mascot-image {
    border-radius: 30px;
    pointer-events: auto;
    transition: background-color 0.3s ease-in-out; 
}

.Contact .page-container-div:hover {
    background-color: #3785f2e6;
}

.Contact .page-container-div:hover p {
    color: white;
}