.top-bar {
  background-color: #e0a12b;
  padding: 20px 20px;
  color: #fff;
  display: flex;
  justify-content: flex-end; /* Align items to the right */
  align-items: center;
}

.top-bar nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-end;
}

.top-bar nav li {
  margin: 10px 30px;
}

.top-bar nav a {
  text-decoration: none;
  color: #030411;
  font-weight: bold;
  font-size: 1.2em;
  transition: font-size 0.3s ease-in-out;
}

.top-bar nav a:hover {
  font-size: 1.25em;
}

.hammer-logo-div {
  position: absolute;
  left: 3%;
  transition: transform 0.3s ease-in-out;
}

.hammer-logo-div:hover {
  transform: scale(1.1);
}